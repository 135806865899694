import React, { Component } from "react";

class Rsvp extends Component {
  render() {

    return (
      <div className="partial text-center">
        <div className="row">
          <div className="rsvp-here col-12 text-center">
            <iframe title={"RSVP form"}
              src="https://docs.google.com/forms/d/e/1FAIpQLSfiTUylwh2w5h1vsma2PHmPsTzTiswjVrlj5fMIuYDcnoRdIg/viewform?embedded=true"
              width="640" height="1000" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
            </iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default Rsvp;