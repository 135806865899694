import React, { Component } from "react";
import RsvpHere from "./RsvpHere";

function TravelCopy() {

  return (
    <div className="our-story-copy row">
      <div className="col-4">
        <p>
          <strong>Air</strong>
          <br/>
          Chennai International (MAA)<br/>
          <small>18kms / 45mins to venue.</small>
        </p>
        Kolkata - <a target={"_blank"}  rel="noopener noreferrer"
                     href={"https://www.cleartrip.com/flights/results?adults=1&childs=0&infants=0&depart_date=05/11/2024&return_date=&intl=n&from=CCU&to=MAA&airline=&carrier=&sd=1727590173070&page=&sellingCountry=IN&ssfi=&flexi_search=&ssfc=&origin=CCU%20-%20Kolkata,%20IN&destination=MAA%20-%20Chennai,%20IN&class=Economy&sft="}>05</a> | <a target={"_blank"}  rel="noopener noreferrer"
        href={"https://www.cleartrip.com/flights/results?adults=1&childs=0&infants=0&depart_date=06/11/2024&return_date=&intl=n&from=CCU&to=MAA&airline=&carrier=&sd=1727590173070&page=&sellingCountry=IN&ssfi=&flexi_search=&ssfc=&origin=CCU%20-%20Kolkata,%20IN&destination=MAA%20-%20Chennai,%20IN&class=Economy&sft="}>06</a><br/>
        Kuala Lumpur - <a target={"_blank"}  rel="noopener noreferrer"
        href={"https://www.agoda.com/en-in/flights/results?cid=1844104&departureFrom=KUL&departureFromType=0&arrivalTo=MAA&arrivalToType=1&departDate=2024-10-05&returnDate=2024-10-07&searchType=1&cabinType=Economy&adults=1&sort=8"}>05</a> | <a target={"_blank"}  rel="noopener noreferrer"
        href={"https://www.agoda.com/en-in/flights/results?cid=1844104&departureFrom=KUL&departureFromType=0&arrivalTo=MAA&arrivalToType=1&departDate=2024-10-06&returnDate=2024-10-08&searchType=1&cabinType=Economy&adults=1&sort=8"}>06</a><br/><br />
        The eVisa takes up to 3 days. <a href={"https://indianvisaonline.gov.in/evisa/tvoa.html"}  rel="noopener noreferrer">Apply here</a>. Please Whatsapp if you require the Visa invitation letter.
      </div>
      <div className="col-4">
        <p>
          <strong>Train</strong>
          <br />
          MGR Chennai Ctl. (MAS) / Chennai Egmore (MS) / Perambur (PER)<br/>
          <small>~12kms / 30mins to venue.</small>
        </p>

        Howrah - <a target={"_blank"} rel="noopener noreferrer"
           href={"https://www.makemytrip.com/railways/listing/?classCode=&className=All%20Classes&date=20241103&destCity=Chennai&destStn=MAS&srcCity=Howrah&srcStn=HWH"}>03</a> | <a target={"_blank"} rel="noopener noreferrer"
           href={"https://www.makemytrip.com/railways/listing/?classCode=&className=All%20Classes&date=20241104&destCity=Chennai&destStn=MAS&srcCity=Howrah&srcStn=HWH"}>04</a><br/>

      </div>
      <div className="col-4">
        <p>
          <strong>Road</strong>
          <br />
          Chennai Mofussil Bus Terminus, Koyembedu<br/>
          <small>4kms / 10mins to venue.</small>
        </p>

        <p>
          <strong>Metro</strong>
          <br />
          Thirumangalam Metro
           / Koyambedu Metro<br/>
          <small>4kms / 10mins to venue.</small>
        </p>

      </div>
      <div className="col-12"><br/>
        <em>Plan to arrive on Tue, 5th Nov. <br/>Else, arrive at MAA by 5pm, Wed, 6th Nov & stay for
          the wedding on Thu, 7th Nov..</em><br /><br />
        <a target={"_blank"} rel="noopener noreferrer" href={"https://www.tripadvisor.in/Attractions-g304556-Activities-c42-Chennai_Madras_Chennai_District_Tamil_Nadu.html"}>What else to do in Chennai?</a>
      </div>
    </div>
  )
}

function StayCopy() {
  return (
    <div className="qa-copy col-12">
      <div className={"mb-2"}><em>The options in order of preference.</em>
      </div>
      <div>
        <p><a target={"_blank"} rel="noopener noreferrer"
              href={"https://maps.app.goo.gl/GVzP9YvQi3fUgkDi8"}>Somerset Greenways</a><br/>
          <small>18kms / 45mins to venue.</small>
          <br/>Good for families. Close to the beach. A bit far from the venue but Uber works.</p>
        <p><a target={"_blank"} rel="noopener noreferrer"
              href={"https://maps.app.goo.gl/J9SHvA1oK67raDr96"}>ITC Grand Chola</a><br/>
          <small>12kms / 40mins to venue.</small>
          <br/>Pricey. White marble everywhere. Close to the airport.</p>
        <p><a target={"_blank"} rel="noopener noreferrer"
              href={"https://maps.app.goo.gl/WNh6prJbEugzwFeu5"}>Taj Coromandel</a><br/>
          <small>10kms / 25mins to venue.</small>
          <br/>Chennai's go-to 5-star hotel. Good last minute fares. <em>Try the Gilli briyani</em>.
        </p>
        <p><a target={"_blank"} rel="noopener noreferrer"
              href={"https://maps.app.goo.gl/PwvTKEHj4wttj8gN7"}>Green Park</a><br/>
          <small>7kms / 25mins to venue.</small>
          <br/>Good Andhra food. Live close to the Bride. Large Mall next door.</p>
        <p><a target={"_blank"} rel="noopener noreferrer"
              href={"https://maps.app.goo.gl/1t8fDfqdfanoWCDq5"}>GRT Grand</a><br/>
          <small>12kms / 30mins to venue.</small>
          <br/>Good South Indian hospitality.</p>
        <p><a target={"_blank"} rel="noopener noreferrer"
              href={"https://maps.app.goo.gl/eKbtPNMxwNB4qHKk9"}>Residency Towers</a><br/>
          <small>12kms / 30mins to venue.</small>
          <br/>Getting old. Close to T Nagar shopping.</p>
        <p>
          <a target={"_blank"} rel="noopener noreferrer"
            href={"https://www.cleartrip.com/hotels/results?city=Chennai&state=Tamil+Nadu&country=IN&poi=&hotelId=&dest_code=33070&chk_in=06%2F11%2F2024&chk_out=10%2F11%2F2024&localityId=&version=V2&areaName=&areaId=&roadName=&roadId=&displaySuggestionType=City&baseLocation=CITY-33070&adults=2&childs=0&num_rooms=1&adults1=2&children1=0"}>Other
            options</a>
        </p>
        <p><em>If you are a larger group, please contact us. There are a few good AirBnb options near the venue and we would be happy to host you.</em></p>
      </div>
    </div>
  )
}

class Travel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showStory: true,
    }

    this.clickStory = this.clickStory.bind(this);
    this.clickTidbits = this.clickTidbits.bind(this);
  }

  clickStory() {
    this.setState({
      showStory: true,
    })
  }

  clickTidbits() {
    this.setState({
      showStory: false,
    })
  }

  render() {
    const {showStory} = this.state

    return (
      <div className="partial text-center">
        <div className="row">
          <div className="headerImage col-12 col-sm-8 offset-sm-2">
            <img src="./chennai.jpg" alt="Travel"/>
          </div>
          <div className="col-2"></div>

          <div className="details col-12 col-sm-10 offset-sm-1">
            <div className="subheader-subnav">
              <div onClick={this.clickStory} className="our-story-subhead subhead col-6"
                   style={{
                     fontWeight: this.state.showStory
                       ? 'bold'
                       : 'normal'
                   }}>
                TRAVEL
              </div>
              <div onClick={this.clickTidbits} className="qa-subhead subhead col-6"
                   style={{
                     fontWeight: !this.state.showStory
                       ? 'bold'
                       : 'normal'
                   }}>
                STAY
              </div>
            </div>

            {showStory
              ? <TravelCopy/>
              : <StayCopy/>
            }

          </div>
          <RsvpHere/>
          <div className="vector col-12 text-center">
            <img src="./vector.png" alt="vector"/>
          </div>
        </div>
      </div>
    );
  }
}

export default Travel;