import React, {Component} from "react";
import Count from "./Count";
import RsvpHere from "./RsvpHere";

class Welcome extends Component {

 render() {
  return (
    <div className="partial text-center">
     <div className="row">
      <div className="headerImage col-12 col-sm-6 offset-sm-3">
       <img src="./collage.jpg" alt="Lekha Vatsan collage"/>
      </div>

      <div className="intro col-10 offset-1">
       <p>We joyfully invite you to our wedding celebration!</p>
       <p>Please join us, along with your family & loved ones,<br/> to share in the joy of this
        special occasion.</p>
       <p>Your blessings as we begin this new chapter of our lives<br/> will be cherished forever.
       </p>
       <p>We can’t wait to celebrate with you!</p>
      </div>

      <div className="vector col-12 text-center">
       <img src="./vector.png" alt="SandS vector"/>
      </div>

      <div className="details col-6">
       <div className="venue">
        Reception
       </div>
       <div className="time">
        Wednesday, 6th November 2024
       </div>
       <div className="date">
        7pm onwards<br/>followed by dinner<br />
        <a className={"hyperlink"} target={"_blank"}  rel="noopener noreferrer"   href={"https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20241106T133000Z%2F20241106T173000Z&details=Join%20us%20in%20celebrating%20our%20wedding%20reception.%0AFor%20more%20info%2C%20please%20visit%20https%3A%2F%2Flekha.vatsan.in&location=SKV%20MAHAL%2C%206%2C%20TNEB%20Road%2C%20opp.%20EB%20Office%20Road%2C%20Golden%20George%20Nagar%2C%20Mogappair%20East%2C%20Chennai&text=Lekha%20%26%20Vatsan%20-%20Reception"}>Add to calendar</a>
       </div>
      </div>
      <div className="details col-6">
       <div className="venue">
        Wedding
       </div>
       <div className="time">
        Thursday, 7th November 2024
       </div>
       <div className="date">
        9.30-11am<br/>breakfast followed by lunch<br/>
        <a className={"hyperlink"} target={"_blank"} rel="noopener noreferrer"
           href={"https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20241107T040000Z%2F20241107T063000Z&details=Join%20us%20in%20celebrating%20our%20wedding%20reception.%0AFor%20more%20info%2C%20please%20visit%20https%3A%2F%2Flekha.vatsan.in&location=SKV%20MAHAL%2C%206%2C%20TNEB%20Road%2C%20opp.%20EB%20Office%20Road%2C%20Golden%20George%20Nagar%2C%20Mogappair%20East%2C%20Chennai&text=Lekha%20%26%20Vatsan%20-%20Wedding"}>Add
         to calendar</a>

       </div>
      </div>
      <div className="details col-10 offset-1">
       <div className="copy">
        <h3>SKV Mahal</h3>
        <div className="address">
         #6, TNEB Road, Mogappair East, Chennai 600 037.
        </div>
        <a href={"https://maps.app.goo.gl/SakiCSuC9cfYSNyu5"} target={"_blank"}
           rel="noopener noreferrer" className={"hyperlink"}>View on the map</a>
       </div>
       <Count/>
      </div>

      <RsvpHere/>
     </div>
    </div>
  );
 }
}

export default Welcome;

