import React, { Component } from "react";

class Registry extends Component {

  render() {
    return (
      <div className="partial text-center">
        <div className="details col-10 offset-1 col-sm-8 offset-sm-2">
         <h3>Thank you for joining us for the live stream.</h3>

        </div>
      </div>
    );
  }
}

export default Registry;