import React, { Component } from "react";

class PhotoButton extends Component {

  render() {
    return (
      <div className="rsvp-here col-12 text-center">
       <a href={'https://photos.app.goo.gl/16xVm67XfowWtZia6'} target={"_blank"}
          rel="noopener noreferrer">
        <div className="rsvp-button">
         Join Google album
      </div>
       </a>
  </div>
  )
   ;
  }
}

export default PhotoButton;