import React, { Component } from "react";
import PhotoButton from "./PhotoButton";
 
class Registry extends Component {

  render() {
    return (
      <div className="partial text-center">
        <div className="subheader">
          # no hashtags
        </div>
        <div className="row">
         <div className="details col-10 offset-1 col-sm-8 offset-sm-2">
          <div className="copy">
           If you’re familiar with us, you know we cherish privacy and we highly respect yours. We’d
           love for you to be part of our special moments and would like to keep it <em>private</em>.
           Please join the shared Google Album using the link below. Feel free to upload any photos
           or videos you’ve snapped during or after the wedding and check out the amazing shots
           taken by others, including our talented photographers. <br/><br/>We will make the album
           private a couple of days after the wedding. Thanks so much for being a part of our lives,
           and we can’t wait to see the memories you’ve captured! 😊🌟
          </div>
          <div className="vector col-12 text-center">
           <img src="./vector.png" alt="vector"/>
          </div>

          <h4><em>If you missed joining the album, please get in touch with us.</em></h4>
         </div>
        </div>
      </div>
    );
  }
}

export default Registry;